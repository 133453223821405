/* eslint-disable no-console */
import oBlock from '@/objects/o-block/o-block.vue';
import oTitle from '@/objects/o-title/o-title.vue';
import oButton from '@/objects/o-button/o-button.vue';
import oButtonLanguage from '@/objects/o-button-language/o-button-language.vue';

export default {
  name: 'home',
  components: {
    'o-block': oBlock,
    'o-title': oTitle,
    'o-button': oButton,
    'o-button-language': oButtonLanguage
  },
  data() {
    return {
      to: null
    };
  },
  computed: {
    zoneId() {
      return this.$store.getters.zoneId
    },
    title() {
      let zoneTitle = ''
      if (this.zoneId !== null && this.$store.getters.content) {
        zoneTitle = this.$store.getters.content[this.zoneId].zoneTitle;
      }
      return zoneTitle;
    },
    intro() {
      let zoneIntro = ''
      if (this.zoneId !== null && this.$store.getters.content) {
        zoneIntro = this.$store.getters.content[this.zoneId].zoneIntro;
      }
      return zoneIntro;
    },
    text() {
      let zoneText = ''
      if (this.zoneId !== null && this.$store.getters.content) {
        zoneText = this.$store.getters.content[this.zoneId].zoneText;
      }
      return zoneText;
    }
  }
};
