export default {
  name: 'oButtonLanguage',
  props: {
    label: String,
    locale: String
  },
  methods: {
    setLanguage() {
      this.$i18n.locale = this.locale;
      this.$store.state.locale = this.locale;
    },
    getClass() {
      if (this.locale === this.$store.state.locale) {
        return 'active';
      }
    }
  }
};
